import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'booleanString'
})
export class BooleanStringPipe implements PipeTransform {

  constructor(private translate: TranslateService) { }

  transform(value: boolean): string {
    return this.translate.instant(value ? 'project.yes' : 'project.no');
  }
}
