export const DEFAULT_LANGUAGE = 'en';

export const BASIC_USER = 'basicUser';

export const MAX_TECHNIQUE_NAME_KEY_LENGTH = 31;

export const ALL_TECHNIQUE_FILTER = 'ALL'

export enum Permissions {
  admin = 'ADMIN',
  superAdmin = 'SUPER_ADMIN'
}

export enum ApiTypes {
  fd = 1,
  iso = 2,
  data2rig = 5
}
