import { Pipe, PipeTransform } from '@angular/core';
import { Equipment } from '@models/User.model';

@Pipe({name: 'searchEquipmentsPipe'})
export class SearchEquipmentsPipe implements PipeTransform {
  transform(equipments: Equipment[], searchString: string): Equipment[] {
    if (!searchString) {
      return equipments;
    }
    return equipments.filter((equipment: Equipment) => this.search(searchString, equipment));
  };

  private search(stringValue: string, equipment: Equipment): boolean {
    return equipment.equipmentType?.equipmentManufacturer?.manufacturerName?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.equipmentName?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.shortName?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.constructionNumber?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.equipmentType?.typeName?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.serialNr?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.billingNr?.toLowerCase().includes(stringValue.toLowerCase())
      || equipment.rentalContract?.targetEquipment?.organization?.orgName?.toLowerCase().includes(stringValue.toLowerCase());
  }
}
