import { UserStaticMenuItem } from '@fielddata.io/header';

export const serverDateFormat = 'YYYY-MM-DD';

export const SNACKBAR_DURATION = 2000;

export const dynamicRoutes = {
  projects: '/projects',
  equipment: '/equipment',
  admin: '/admin',
  myProjects: '/projects/my-projects',
  allProjects: '/projects/all-projects',
  projectDetails: '/projects/project/',
  createProject: '/projects/project-editor/create',
  createEquipment: '/equipment/create',
  equipmentList: '/equipment/list',
  addRentalCode: '/equipment/add-rental-equipment',
  rentEquipment: '/equipment/rent-out-equipment',
  rentalContracts: '/equipment/rental-contracts',
  createUserRole: '/admin/create-roles',
  assignUserRole: '/admin/assign-roles',
  assignRoleConfig: '/admin/role-configuration',
  createOrganization: '/admin/create-org',
  editOrganization: '/admin/edit-org',
  portalRights: '/admin/portal-rights',
  resetBasicUser: '/admin/reset-basic-user',
  userSettings: '/configuration/user-settings',
  logout: '/action/logout',
  changeAllowedEquipment: '/admin/change-allowed-equipment',
  appAssignment: '/admin/app-assignment',
  editUser: '/admin/edit-user'
};

export const compare = function(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

export const getDynamicRoute = function(key: string) {
  return (dynamicRoutes[key] ? dynamicRoutes[key] : null);
};

export const userStaticMenu = [
  {
    translateKey: 'menu.settings',
    internalLink: getDynamicRoute('userSettings')
  } as UserStaticMenuItem,
  {
    translateKey: 'menu.logout',
    internalLink: getDynamicRoute('logout')
  } as UserStaticMenuItem
];

